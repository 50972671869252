<template>
    <div id="home">
        <HomeVideo />
        <div class="home-about">
            <div class="home-about-container">
                <div class="desktop-only left-side">
                    <p>
                        WALTERS | PLAXEN
                    </p>
                </div>
                <div class="photo">
                    <img data-aos="fade-up" src="/images/about/about.jpg" alt="Walter Plaxen">
                </div>
                <div class="bio">
                    <h1 data-aos="fade-up">
                        WALTERS |<br>PLAXEN
                    </h1>
                    <p data-aos="fade-up">
                        Introducing Shauna and Nicole of Walters | Plaxen Estates: a dynamic duo in the world of luxury real estate who have nurtured a remarkable 25-year bond while establishing themselves as industry leaders. At the heart of their mission lies a profound commitment to women's empowerment, a cause they passionately champion alongside esteemed partners Branden and Rayni Williams at The Beverly Hills Estates.<br><br>
                        Forged through shared experiences and an unbreakable bond, this instantly iconic female pair brings a unique perspective and a deep commitment to building, uplifting, and mentoring women in the industry and on their team—a niche that sets them apart. Aligning passion and purpose, Shauna and Nicole consistently achieve record-breaking sales while maintaining unwavering support for their clients and their commitment to advancing women in the real estate industry.
                    </p>
                    <router-link data-aos="fade-up" class="btn" to="/about">
                        Read More
                        <img src="/images/icon/arrow.svg" alt="Read More">
                    </router-link>
                </div>
                <div class="right-side">
                    <img src="/images/logo-wp.svg" alt="SW logo">
                </div>
            </div>
        </div>
        <div class="stats">
            <div class="stats-container">
                <div class="stat">
                    <ICountUp
                        v-observe-visibility="visibilityChanged1"
                        :delay=300
                        :endVal="numbersVisible1 ? 500 : 0"
                        :options="options1"
                        class="countup"
                    />
                    <h3 class="unit">
                        Million in sales
                    </h3>
                </div>
                <div class="stat">
                    <ICountUp
                        v-observe-visibility="visibilityChanged2"
                        :delay=300
                        :endVal="numbersVisible2 ? 40 : 0"
                        :options="options2"
                        class="countup"
                    />
                    <h3 class="unit">
                        Years combined experience
                    </h3>
                </div>
                <div class="stat">
                    <ICountUp
                        v-observe-visibility="visibilityChanged3"
                        :delay=300
                        :endVal="numbersVisible3 ? 200 : 0"
                        :options="options3"
                        class="countup"
                    />
                    <h3 class="unit">
                        Million in active listings
                    </h3>
                </div>
            </div>
            <div class="read-more">
                <router-link class="btn" to="/contact">
                    CONTACT TODAY <img src="/images/icon/arrow-white.svg" alt="Read More" />
                </router-link>
            </div>
        </div>
        <div class="featured-slideshow">
            <h1 data-aos="fade-up">
                FEATURED LISTINGS
            </h1>
            <featured-properties />
        </div>
        <hr>
        <div class="press">
            <h1 data-aos="fade-up">
                PRESS
            </h1>
            <div v-if="!$store.getters.getPressLoaded">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <div v-else class="press-container">
                <div data-aos="fade-up" :data-aos-delay="$store.getters.isMobile ? 1 : (i%3+1)*400" v-for="(article, i) in press" class="press-item" :key="i">
                    <a class="overlay-container" :href="article.ArticleURL" target="_blank" rel="noopener noreferrer">
                        <img class="prees-image" :src="url+article.Image.url" :alt="article.Title">
                        <div class="overlay">
                            <a class="btn" :href="article.ArticleURL" target="_blank" rel="noopener noreferrer">
                                Read Article <img class="arrow" src="/images/icon/arrow-white.svg" alt="Read More" />
                            </a>
                        </div>
                    </a>
                    <h3>
                        {{article.Title}}
                    </h3>
                </div>
            </div>
            <router-link data-aos="fade-up" class="btn" to="/press">
                See All Press <img src="/images/icon/arrow.svg" alt="Read More" />
            </router-link>
        </div>
    </div>
</template>

<script>
import HomeVideo from '../components/HomeVideo'
import Spinner from 'vue-simple-spinner'
import FeaturedProperties from '../components/FeaturedProperties';
import lazyLoadComponent from '../utils/lazy-load-component';
import SkeletonBox from '../components/SkeletonBox';

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

export default {
    name: "Home",
    components: {
        HomeVideo,
        Spinner,
        FeaturedProperties,
        ICountUp: lazyLoadComponent({
            ...defaultOptions,
            componentFactory: () => import(`vue-countup-v2`),
        }),
    },
    metaInfo: {
        title: 'WALTERS | PLAXEN',
        meta: [{
            name: 'description', 
            content: "WALTERS | PLAXEN"
        }]
    },
    data(){
        return{
            loading: true,
            url: "https://strapi.uptowncreative.io",
            numbersVisible1: false,
            numbersVisible2: false,
            numbersVisible3: false,
            options1: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '$',
                suffix: '+',
                enableScrollSpy: true,
            },
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            options3: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '$',
                suffix: '+',
            }
        }
    },
    mounted:function(){
        if(!this.$store.getters.loaded || this.$store.getters.checkExpiryDate){
            this.$store.dispatch('updateProperties');
        }
    },
    methods:{
        visibilityChanged1(isVisible){
            this.numbersVisible1 = isVisible;
        },
        visibilityChanged2(isVisible){
            this.numbersVisible2 = isVisible;
        },
        visibilityChanged3(isVisible){
            this.numbersVisible3 = isVisible;
        },
    },
    computed: {
        press:function(){
            return this.$store.getters.press.slice(0,3);
        },
    },
}   
</script>

<style lang="scss" scoped>
.home-about-container{
    margin: 40px;
    display: flex;
    align-items: center;
    position: relative;
    .left-side{
        width: 5%;
        p{
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.1em;
            writing-mode: vertical-rl;
        }
    }
    .photo{
        width: 40%;
        padding-right: 40px;
        img{
            display: block;
            width: 100%;
            object-fit: cover;
        }
    }
    .bio{
        width: 45%;
        padding-left: 40px;
        h1{
            margin-bottom: 20px;
        }
        p{
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    .right-side{
        width: 5%;
        align-self: flex-end;
        img{
            position: absolute;
            width: 150px;
        }
    }
}
.stats{
    text-transform: uppercase;
    margin-top: 160px;
    background: url('/images/stats-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    a{
        display: inline-block;
        margin-top: 40px;
        color: #fff;
    }
    &-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .stat{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 33.33%;
            .countup{
                font-size: 140px;
                line-height: 97px;
                letter-spacing: 0.01em;
                margin-bottom: 0;
            }
            .unit{
                font-weight: 275;
                font-size: 28px;
                line-height: 27px;
                letter-spacing: 0.01em;
            }
        }
    }
}

.press{
    text-align: center;
    .press-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin: 80px 160px 40px;
        .prees-image{
            width: 100%;
        }
    }
    .press-item{
        h3{
            text-align: left;
            text-transform: uppercase;
        }
    }
    &>.btn{
        display:inline-block;
        margin-bottom: 60px;
    }
}

.featured-slideshow{
    text-align: center;
}


@media (max-width: $tablet) {
    .press{
        text-align: center;
        .press-container{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
            margin: 80px 80px 40px;
            .prees-image{
                width: 100%;
            }
        }
    }
    .stats{
        margin-top: 80px;
        height: unset;
        min-height: 400px;
        &-container{
            width: 100%;
            flex-wrap: wrap;
            margin: 60px 0 0;
            .stat{
                width: 100%;
                margin: 40px 0;
                .countup{
                    font-size: 80px;
                    line-height: 77px;
                    letter-spacing: 0.01em;
                    margin-bottom: 0;
                }
                .unit{
                    margin: 20px;
                    text-align: center;
                }
            }
        }
        a{
            display: inline-block;
            margin-top: unset;
            margin-bottom: 40px;
            color: #fff;
            margin-bottom: 90px;
        }
    }
}
@media (max-width: $mobile) { 
    .home-about-container{
        flex-wrap: wrap;
        .photo{
            width: 100%;
            padding-right: 0;
            img{
                display: block;
                width: 100%;
                object-fit: cover;
            }
        }
        .bio{
            width: 100%;
            padding-left: 0;
            text-align: center;
        }
        .right-side{
            width: 100%;
            align-self: center;
            margin-top: 80px;
            img{
                position: unset;
                margin: 0 auto;
                display: block;
            }
        }
    }
    .press{
        .press-container{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0 20px;
            margin: 80px 40px 40px;
        }
        img{
            width: 100%;
        }
        .btn{
            img{
                width: unset;
            }
        }
    }
}
</style>
