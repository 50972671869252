<template>
    <section class="homevideo">
        <div class="homevideo__container">
            <div class="homevideo__background">
                <div class="homevideo__background__video">
                    <figure class="homevideo__background__asset">
                        <video 
                            autoplay
                            class="video"
                            data-load="objectFit"
                            loop
                            muted
                            playsinline>
                            <source src="https://api.uptowncreative.io/uploads/_/assets/chadrogers/swla-video.mp4" type="video/mp4">
                        </video>
                    </figure>
                </div>
                <div class="homevideo__background__mobile">
                    <!-- <figure class="homevideo__background__asset">
                        poster="/images/initial-slide.jpg"
                        <img alt="SWLA"  src="/images/initial-slide.jpg">
                    </figure> -->
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.homevideo{
    &__container{
        height: 100vh;
        width: 100%;
        min-height: 480px;
    }
    &__background{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        &__video{
            display:block;
        }
        &__asset{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            .video{
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__mobile{
            display: none;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    &__gradient{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, #000, #000);
        opacity: 0.35;
    }
    &__foreground{
        position: relative;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        color: #fff;
    }
    p{
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 2px;
        color: #fff;
        opacity: 1;
        text-transform: uppercase;
        margin: 0;
        margin-left: 4.25rem;
    }
    h1{
        font-size: 65px;
        color: #fff;
        font-weight: 100;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin: 0.5rem 0;
        margin-left: 4rem;
    }
}
figure{
    padding: 0;
    margin: 0;
}

@media (max-width: $tablet) {
    .homevideo{
        &__foreground{
            h1{
                line-height: 1;
                margin-left: 2rem;
            }
            p{
                margin-left: 2rem;
            }
        }
    }
}
</style>

<script>
export default {
    name:"HomeVideo",
}
</script>